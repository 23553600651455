// Colours
$bodyColour: #46412A;
$headingColour: darken($bodyColour, 5%);
$linkColour: #006CD8;
$hoverColour: darken($linkColour, 10%);
$captionColour: lighten($headingColour, 40%);
$white: #fff;

$backgroundColour: #F9FCFF;
$codeBackgroundColour: darken($backgroundColour, 5%);
